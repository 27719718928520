import { useEffect, useMemo, useState } from "react";
import { useRoles } from "@Hooks/useRoles/useRoles";
import { CircleAlertIcon, TrashCanIcon, ValidatingIcon } from "@Models/icons";
import style from "./FormAddMember.module.css";
import {
  TextInput,
  Icon,
  SelectInput,
  ModalSelectServices,
  ModalSelectSchedules,
  DateInput,
  // @ts-ignore
} from "@viuti/recursos";
import { initialSchedulesState } from "./initialSchedulesState";
import { getSearchEmployeeReniec_adapter } from "@Adapters/getSearchEmployeeReniec/getSearchEmployeeReniec.adapter";

export const FormAddMember = ({
  uuid,
  setForm,
  form,
  onDelete,
  disableDelete,
  setIsSearchingReniec,
}) => {
  const [schenduleModalVisible, setSchenduleModalVisible] = useState(false);
  const [servicesModalVisible, setServicesModalVisible] = useState(false);
  const [tempSelectedServices, setTempSelectedServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedSchedules, setSelectedSchedules] = useState(
    initialSchedulesState
  );
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [tempEmployeeSchedules, setTempEmployeeSchedules] = useState(
    initialSchedulesState
  );
  const [loadingSearchReniec, setLoadingSearchReniec] = useState({
    id: null,
    loading: false,
  });
  const isDisabled =
    loadingSearchReniec.loading && loadingSearchReniec.id === uuid;

  const handleSaveSchedules = () => {
    setSelectedSchedules(tempEmployeeSchedules);
    setSchenduleModalVisible(false);
    const updatedForm = form.map((item) =>
      item.uuid === uuid ? { ...item, schedules: tempEmployeeSchedules } : item
    );
    setForm(updatedForm);
  };

  const handleCancelSchedules = () => {
    setTempEmployeeSchedules(selectedSchedules);
    setSchenduleModalVisible(false);
  };

  const handleOpenServicesModal = () => {
    if (isDisabled) return;
    setTempSelectedServices(selectedServices);
    setServicesModalVisible(true);
  };

  const handleSaveServices = (services) => {
    setSelectedServices(services);
    setServicesModalVisible(false);
    if (services.length === 0) {
      setError((prev) => ({
        ...prev,
        services: "Debe seleccionar al menos un servicio",
      }));
    } else {
      setError((prev) => ({ ...prev, services: "" }));
    }
    // Actualizar el formulario con los servicios seleccionados
    const updatedForm = form.map((item) =>
      item.uuid === uuid ? { ...item, services: services } : item
    );
    setForm(updatedForm);
  };

  const handleCancelServices = () => {
    setTempSelectedServices(selectedServices);
    setServicesModalVisible(false);
    if (selectedServices.length === 0) {
      setError((prev) => ({
        ...prev,
        services: "Debe seleccionar al menos un servicio",
      }));
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    const currentDocType = form.find(
      (item) => item.uuid === uuid
    )?.documentType;
    // Validación para nombre, apellido y alias
    if (name === "name" || name === "lastName" || name === "alias") {
      // Regex para permitir solo letras y espacios
      const onlyLetters = /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]*$/;
      if (!onlyLetters.test(value)) {
        return; // No actualiza si contiene números o símbolos
      }
    }

    const updatedForm = form.map((item) =>
      item.uuid === uuid ? { ...item, [name]: value } : item
    );

    if (name === "document") {
      // Verificar que solo sean números

      // Limpiar errores si el campo está vacío
      if (value === "") {
        setError((prev) => ({
          ...prev,
          document: "",
        }));
        setForm(updatedForm);
        return;
      }

      // Verificar si el documento está duplicado
      const isDuplicate = form.some(
        (item) => item.document === value && item.uuid !== uuid
      );

      if (isDuplicate) {
        setError((prev) => ({
          ...prev,
          document: "El número de documento está duplicado",
        }));
        setForm(updatedForm);
        return;
      }
      // si el tipo de documento es DNI
      if (currentDocType === 2) {
        if (isNaN(value)) {
          return;
        }
        // Validación de longitud
        if (value.length < 8) {
          setError((prev) => ({
            ...prev,
            document: "El número de documento debe tener 8 dígitos",
          }));
        } else if (value.length === 8) {
          setError((prev) => ({ ...prev, document: "" }));
        }
      }
      // si el tipo de documento es Carnet de Extranjería o Pasaporte
      if (currentDocType === 3 || currentDocType === 4) {
        const alphanumericRegex = /^[A-Za-z0-9ñÑ]*$/;
        if (!alphanumericRegex.test(value) || value.length > 20) {
          setError((prev) => ({
            ...prev,
            document:
              "El número de documento debe ser alfanumérico y tener máximo 20 dígitos",
          }));
        } else {
          setError((prev) => ({ ...prev, document: "" }));
        }
      }
    }

    // Validación específica para el rol
    if (name === "rol") {
      if (!value) {
        setError((prev) => ({ ...prev, rol: "Campo requerido" }));
      } else {
        setError((prev) => ({ ...prev, rol: "" }));
      }
    }

    setForm(updatedForm);

    if (name === "document") {
      if (value.length === 8 && currentDocType === 2) {
        setLoadingSearchReniec({ id: uuid, loading: true });
        setIsSearchingReniec(true);
        const response = await getSearchEmployeeReniec_adapter(value);
        // Autocompletar los campos con la respuesta
        setForm((prevForm) =>
          prevForm.map((item) =>
            item.uuid === uuid
              ? {
                  ...item,
                  document: value,
                  name: response.data.name,
                  lastName: response.data.lastName,
                  dateOfBirth: response.data.dateOfBirth,
                  sex: response.data.gender,
                }
              : item
          )
        );
        setIsSearchingReniec(false);
        setLoadingSearchReniec({ id: null, loading: false });
      }
    }
  };
  const sexOptions = useMemo(
    () => [
      { value: 1, name: "Masculino" },
      { value: 2, name: "Femenino" },
      { value: 3, name: "Otro" },
    ],
    []
  );
  const [error, setError] = useState({
    documentType: "",
    document: "",
    sex: "",
    dateOfBirth: "",
    name: "",
    lastName: "",
    alias: "",
    rol: "",
    email: "",
    telephoneNumber: "",
    services: "",
    schedules: "",
  });

  const handleBlurName = (e) => {
    if (!e.target.value) {
      setError((prev) => ({ ...prev, name: "Campo requerido" }));
    }
    if (e.target.value.length < 3 && e.target.value.length > 0) {
      setError((prev) => ({ ...prev, name: "Mínimo 3 caracteres" }));
    }

    if (e.target.value.length >= 3) {
      setError((prev) => ({ ...prev, name: "" }));
    }
  };

  const handleBlurLastName = (e) => {
    if (!e.target.value) {
      setError((prev) => ({ ...prev, lastName: "Campo requerido" }));
    }
    if (e.target.value.length < 3 && e.target.value.length > 0) {
      setError((prev) => ({ ...prev, lastName: "Mínimo 3 caracteres" }));
    }

    if (e.target.value.length >= 3) {
      setError((prev) => ({ ...prev, lastName: "" }));
    }
  };

  const handleBlurAlias = (e) => {
    if (!e.target.value) {
      setError((prev) => ({ ...prev, alias: "Campo requerido" }));
    }
    if (e.target.value.length >= 2) {
      setError((prev) => ({ ...prev, alias: "" }));
    }
  };

  const handleBlurEmail = (e) => {
    const isDuplicate = form.some(
      (item) => item.email === e.target.value && item.uuid !== uuid
    );

    if (!e.target.value) {
      setError((prev) => ({ ...prev, email: "Campo requerido" }));
    } else if (!isEmailValid(e.target.value)) {
      setError((prev) => ({ ...prev, email: "Correo inválido" }));
    } else if (isDuplicate) {
      setError((prev) => ({
        ...prev,
        email: "El correo electrónico está duplicado",
      }));
    } else {
      setError((prev) => ({ ...prev, email: "" }));
    }
  };

  const isEmailValid = (email) => {
    const emailRegex = /^([\w.%+-ñÑ]+)@([\w-ñÑ]+\.)+([\wñÑ]{2,})$/i;
    return emailRegex.test(email);
  };

  const handleBlurTelephoneNumber = (e) => {
    if (!e.target.value) {
      setError((prev) => ({ ...prev, telephoneNumber: "Campo requerido" }));
    } else if (e.target.value.length !== 9) {
      setError((prev) => ({
        ...prev,
        telephoneNumber: "Debe tener 9 dígitos",
      }));
    } else {
      setError((prev) => ({ ...prev, telephoneNumber: "" }));
    }
  };

  const handleBlurRol = () => {
    if (form.find((item) => item.uuid === uuid)?.rol === "") {
      setError((prev) => ({ ...prev, rol: "Campo requerido" }));
    } else {
      setError((prev) => ({ ...prev, rol: "" }));
    }
  };

  const { roles } = useRoles();
  const documentsType = [
    {
      name: "DNI",
      value: 2,
    },
    {
      name: "Carnet de Extranjería",
      value: 3,
    },
    {
      name: "Pasaporte",
      value: 4,
    },
  ];

  useEffect(() => {
    // Actualiza el form cuando cambia la fecha de nacimiento local
    if (fechaNacimiento) {
      const updatedForm = form.map((item) =>
        item.uuid === uuid ? { ...item, dateOfBirth: fechaNacimiento } : item
      );
      setForm(updatedForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaNacimiento, uuid, setForm]);

  useEffect(() => {
    // También actualizar el estado local cuando viene la fecha de RENIEC
    const currentMember = form.find((item) => item.uuid === uuid);
    if (currentMember?.dateOfBirth) {
      setFechaNacimiento(currentMember.dateOfBirth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, uuid]);

  return (
    <div className={style.containerFormAddMember}>
      <div className={style.trashContainer}>
        <button
          className={style.trashIcon}
          onClick={() => onDelete(uuid)}
          disabled={disableDelete}
        >
          <Icon
            path={TrashCanIcon}
            color={disableDelete ? "#9f9f9f" : "#C36363"}
            size={16}
          />
        </button>
      </div>

      <div className={style.formCard}>
        <SelectInput
          label="Tipo de documento"
          placeholder={"Selecciona tipo de documento"}
          value={form.find((item) => item.uuid === uuid)?.documentType || ""}
          items={documentsType}
          handleChange={handleChange}
          handleBlur={handleBlurRol}
          touched={error.documentType}
          name="documentType"
          error={error.documentType}
          disabled={isDisabled}
        />
        <div>
          <TextInput
            label="Número de documento"
            placeholder="Número de documento"
            value={form.find((item) => item.uuid === uuid)?.document || ""}
            handleChange={handleChange}
            name="document"
            // handleBlur={handleBlurName}
            error={error.document}
            touched={error.document}
            disabled={isDisabled}
          />
          <span className={style.inputContainerMessage}>
            {isDisabled && (
              <>
                <img
                  src={ValidatingIcon}
                  alt="Validando"
                  className={style.validatingSvg}
                />
                <p className={style.inputValidatingMessage}>
                  Buscando información..
                </p>
              </>
            )}
          </span>
        </div>
        <TextInput
          label="Nombre"
          placeholder="Nombre"
          value={form.find((item) => item.uuid === uuid)?.name || ""}
          handleChange={handleChange}
          name="name"
          handleBlur={handleBlurName}
          error={error.name}
          touched={error.name}
          disabled={isDisabled}
          required
        />

        <TextInput
          label="Apellido"
          placeholder="Apellido"
          value={form.find((item) => item.uuid === uuid)?.lastName || ""}
          handleChange={handleChange}
          name="lastName"
          handleBlur={handleBlurLastName}
          error={error.lastName}
          touched={error.lastName}
          disabled={isDisabled}
          required
        />

        <DateInput
          label="Fecha de nacimiento"
          inputProps={{
            value: fechaNacimiento,
            onChange: (e) => setFechaNacimiento(e.target.value),
          }}
          disabled={isDisabled}
        />
        <SelectInput
          label="Sexo"
          placeholder={"Seleccione género"}
          value={form.find((item) => item.uuid === uuid)?.sex || ""}
          items={sexOptions}
          handleChange={handleChange}
          handleBlur={handleBlurRol}
          touched={error.sex}
          name="sex"
          error={error.sex}
          disabled={isDisabled}
        />

        <TextInput
          label="Alias"
          placeholder="Alias"
          value={form.find((item) => item.uuid === uuid)?.alias || ""}
          handleChange={handleChange}
          name="alias"
          handleBlur={handleBlurAlias}
          error={error.alias}
          touched={error.alias}
          disabled={isDisabled}
          required
        />
        <TextInput
          label="Correo"
          placeholder="Correo"
          value={form.find((item) => item.uuid === uuid)?.email || ""}
          handleChange={handleChange}
          name="email"
          handleBlur={handleBlurEmail}
          error={error.email}
          touched={error.email}
          disabled={isDisabled}
          required
        />
        <TextInput
          label="Teléfono"
          placeholder="Teléfono"
          value={form.find((item) => item.uuid === uuid)?.telephoneNumber || ""}
          handleChange={(e) => {
            if (e.target.value.length > 9) return;
            if (isNaN(e.target.value)) return;
            handleChange(e);
          }}
          name="telephoneNumber"
          handleBlur={handleBlurTelephoneNumber}
          error={error.telephoneNumber}
          touched={error.telephoneNumber}
          disabled={isDisabled}
          required
        />

        <SelectInput
          label="Rol"
          placeholder={"Selecciona un rol"}
          value={form.find((item) => item.uuid === uuid)?.rol || ""}
          items={roles}
          handleChange={handleChange}
          handleBlur={handleBlurRol}
          touched={error.rol}
          name="rol"
          error={error.rol}
          disabled={isDisabled}
          required
        />
        <section className={style.containerServiciobutton}>
          <span className={style.servicesContainer}>
            <h3>
              Servicios
              {/* <span>*</span> */}
              {selectedServices.length > 0 &&
                `: ${selectedServices.length} seleccionado${
                  selectedServices.length > 1 ? "s" : ""
                }.`}
            </h3>
          </span>
          <p className={style.p}>
            <button
              className={style.span}
              onClick={() => handleOpenServicesModal()}
            >
              Seleccionar servicios
            </button>
          </p>
          {/* {error.services && (
            <span className={style.inputErrorContainer}>
              <img src={CircleAlertIcon} alt="alert" />
              <p className={style.inputError}>{error.services}</p>
            </span>
          )} */}
        </section>
        <section className={style.containerServiciobutton}>
          <span className={style.servicesContainer}>
            <h3>
              Horarios
              {/* <span>*</span> */}
            </h3>
          </span>
          <p className={style.p}>
            <button
              onClick={() => {
                if (isDisabled) return;
                setSchenduleModalVisible(true);
              }}
              className={style.span}
            >
              Seleccionar horarios
            </button>
          </p>
          {/* {error.schedules && (
            <p className={style.errorMessage}>{error.schedules}</p>
          )} */}
        </section>

        <ModalSelectServices
          isVisible={servicesModalVisible}
          handleCloseClick={handleCancelServices}
          handleSaveClick={handleSaveServices}
          servicesSelected={tempSelectedServices}
          message="Selecciona los servicios que deseas agregar al empleado"
        />
        <ModalSelectSchedules
          isVisible={schenduleModalVisible}
          employeeSchedules={tempEmployeeSchedules}
          setEmployeeSchedules={setTempEmployeeSchedules}
          handleSubmit={handleSaveSchedules}
          closeModal={handleCancelSchedules}
        />
      </div>
    </div>
  );
};
