import { getSearchEmployeeReniec_service } from "@Services/Employee/getSearchEmployeeReniec.service";
import { capitalizeFirstLetterOfEachWord } from "@Utilities/texts";

export const getSearchEmployeeReniec_adapter = async (document: string) => {
  const response = await getSearchEmployeeReniec_service(document);
  if (!response.isSuccess) {
    return {
      ...response,
      data: {
        name: "",
        lastName: "",
        dateOfBirth: "",
        gender: null,
      },
    };
  }

  const adaptEmployee = {
    name: capitalizeFirstLetterOfEachWord(`${response.data.nombres}`),
    lastName: capitalizeFirstLetterOfEachWord(
      `${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`
    ),
    dateOfBirth: response.data.fechaDeNacimiento,
    gender: mapGender(response.data.sexo),
  };

  return {
    ...response,
    data: adaptEmployee,
  };
};

const mapGender = (sexo: string) => {
  switch (sexo) {
    case "MASCULINO":
      return 1;
    case "FEMENINO":
      return 2;
    default:
      return null;
  }
};
