import {
  IEmployee,
  IEmployeeLoyalty,
  IEmployeeService,
  IEmployeeStats,
} from "@Models/interfaces/employees";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import {
  getEmployeeInfoService,
  getEmployeeLoyaltyDataService,
  getEmployeeServicesService,
  getEmployeeStatsService,
  getEmployeesService,
} from "@Services/gets";
import { capitalizeFirstLetter } from "@Utilities/texts";

export const getEmployeesAdapter = async (setIsLoading, dispatch) => {
  try {
    const response: IResponse = await getEmployeesService();

    const employees: IEmployee[] = response.data.map((employee) => {
      let servicesArray = employee.nameServices?.split("-") || [];
      // filtrar los servicios que no tienen nombre
      servicesArray = servicesArray.filter((service) => service !== "");
      // capitalizar la primera letra de cada servicio
      servicesArray = servicesArray.map((service) =>
        capitalizeFirstLetter(service)
      );
      // filtrar los servicios repetidos
      servicesArray = servicesArray.filter(
        (service, index) => servicesArray.indexOf(service) === index
      );

      return {
        surname: employee.lastName,
        email: employee.email,
        idEmployee: employee.idEmployee,
        // profilePicture: employee.profileImage,
        profilePicture: "",
        name: employee.name,
        services: servicesArray,
        position: employee.rol,
        onboardingDone: employee.confirmed,
        userWithAccess: employee.userWithAccess,
        origin: employee.origin,
      };
    });
    setIsLoading(false);
    return {
      status: response.status,
      message: response.message,
      data: employees.reverse(),
    };
  } catch (error) {
    setIsLoading(false);
    dispatch(
      updateNotificationMessage(
        "Hubo un error al obtener los empleados. Intente de nuevo."
      )
    );
    dispatch(updateNotificationStatus(400));
    return {
      status: 400,
      message: "Hubo un error al obtener los empleados. Intente de nuevo.",
      data: [],
    };
  }
};

export const getEmployeeServicesAdapter = async (idEmployee: number) => {
  const response: IResponse = await getEmployeeServicesService(idEmployee);

  if (!response.isSuccess) {
    return response;
  }

  let services: IEmployeeService[] = response.data.map((service) => ({
    idServiceEmployee: service.idServicesEmployee,
    idService: service.idService,
    name: service.serviceName,
  }));

  // filtrar los servicios repetidos
  services = services.filter(
    (service, index) => services.indexOf(service) === index
  );

  return {
    ...response,
    data: services,
  };
};

export const getEmployeeStatsAdapter = async (idEmployee: number) => {
  const response: IResponse = await getEmployeeStatsService(idEmployee);

  if (!response.isSuccess) {
    return response;
  }

  const formattedResponse: IEmployeeStats[] = response.data.map((stat) => ({
    employeeSurname: capitalizeFirstLetter(stat.employeeLastname),
    clientsServed: stat.attentions,
    employeeName: capitalizeFirstLetter(stat.employeeName),
    period: stat.type,
    totalProducts: stat.totalProducts,
    totalServices: stat.totalServices,
    totalSales: stat.totalSales,
  }));

  return {
    ...response,
    data: formattedResponse,
  };
};

export const getEmployeeLoyaltyDataAdapter = async (idEmployee: number) => {
  const response: IResponse = await getEmployeeLoyaltyDataService(idEmployee);

  if (!response.isSuccess) {
    return response;
  }

  const formattedResponse: IEmployeeLoyalty = {
    clientsServed: response.data.customersServed,
    usualClients: response.data.customersRecurring,
    loyaltyPercentage: response.data.loyalty,
  };

  if (response.status !== 200) {
    return response;
  }

  return {
    ...response,
    data: formattedResponse,
  };
};

export const getEmployeeInfoAdapter = async (idEmployee: number) => {
  const response: IResponse = await getEmployeeInfoService(idEmployee);

  if (!response.isSuccess) {
    return response;
  }

  const data = response.data;
  function formatearFecha(fechaOriginal: string | null): string {
    // Dividimos la fecha original en sus componentes
    if (!fechaOriginal) return "";

    const [fecha] = fechaOriginal.split(" ");
    // Separamos día, mes y año
    const [dia, mes, anio] = fecha.split("/");
    // Reemplazamos las barras por guiones
    const fechaFormateada = `${anio}-${mes.padStart(2, "0")}-${dia.padStart(
      2,
      "0"
    )}`;

    return fechaFormateada;
  }

  const formatResponse: IEmployee = {
    alias: data?.alias || data?.name,
    password: data?.password || "tempPass",
    surname: data?.lastname,
    email: data?.email,
    birthDate: formatearFecha(data?.dateOfBirth),
    idEmployee: data?.idEmployee,
    name: data?.name,
    position: data?.idRol,
    phone: data?.phone,
    profilePicture: data?.profileImage || "",
    isAdmin: data?.admin,
    userWithAccess: data?.userWithAccess,
    gender: data?.sex === "0" ? "" : String(data?.sex),
    documentType: data?.typeDocument || "",
    documentNumber: data?.documentNumber || "",
  };

  return {
    ...response,
    data: formatResponse,
  };
};
