import React from "react";
import style from "./AvailableSeats.module.css";
// @ts-ignore
import { getUserPermissions, IconWrapper } from "@viuti/recursos";
import { useAppSelector } from "@Hooks/store";
import {
  HiMiniCheckCircle,
  HiMiniUserGroup,
  HiExclamationCircle,
} from "react-icons/hi2";

const AvailableSeats = () => {
  const availableSeats = getUserPermissions().employeesLimitCount;
  const employeesState = useAppSelector((state) => state.employeesList);
  const totalEmployees = employeesState.filter(
    (employee) => employee.userWithAccess
  ).length;

  const exceededEmployees = availableSeats - totalEmployees > 0 ? false : true;

  return (
    <article className={style.container}>
      <div className={style.seats_container}>
        <span className={style.users_icon}>
          <IconWrapper icon={HiMiniUserGroup} size={16} color="#45348E" />
        </span>
        <h1 className={style.available_seats}>
          {totalEmployees} empleados activos
        </h1>
      </div>

      {availableSeats > 999 ? null : (
        <div
          className={`${style.available_seats_container} ${
            exceededEmployees ? style.exceeded : ""
          }`}
        >
          <>
            {!exceededEmployees ? (
              <IconWrapper icon={HiMiniCheckCircle} size={18} color="#1AAD70" />
            ) : (
              <IconWrapper
                icon={HiExclamationCircle}
                size={18}
                color="#F23D4E"
              />
            )}
            <p className={style.available_seats_description}>
              Tienes un límite de <b>{availableSeats}</b> usuarios.{" "}
              {exceededEmployees &&
                "Mientras alcances o superes tu límite, no podrás crear o invitar nuevos usuarios."}
            </p>
          </>
        </div>
      )}
    </article>
  );
};

export default AvailableSeats;
